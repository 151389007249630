/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "readmore-js"
import "@appPages/vue"

import "stylesheets/application.sass"
import InputSmooth from "@utils/input-smooth"
import AnimationScroll from "@utils/animation-scroll"
import AnimationScrollValue from "@utils/animation-scroll-value"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import Modal from "@utils/share-modal"
import ConstantHeight from "@utils/constant-height"
import ValueSlider from "@utils/value-slider"
import DownloadCard from "@utils/download-card"
import Menu from "@utils/menu"
import Advertisement from "@utils/advertisement"
import Readmore from "@utils/readmore"

// Fonts ===============================================================
import "typeface-roboto"

// CONFIGURATION ===============================================================
Rails.start()
const application = Application.start()
const context = require.context("@controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

window.FlashMessage = FlashMessage

window.recaptachCallback = () => {
  document.getElementById('recaptcha_validated_form').submit()
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('[data-trigger-submit]').forEach(el => {
    el.addEventListener('change', () => el.closest('form').submit())
  })

  new AnimationScroll()
  new InputSmooth()
  new ImageObjectFit()
  new Modal()
  new ValueSlider()
  new AnimationScrollValue()
  new Menu()
  new ConstantHeight()
  new Advertisement()
  new DownloadCard()
  new Readmore()
})
