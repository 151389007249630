<template lang="pug">
  div
    slot(:update-rent-type="updateRentType" :rentType="rentType")
</template>

<script>

export default {
  props: {
    initialRentType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      rentType: this.initialRentType
    }
  },

  methods: {
    updateRentType (e) {
      this.rentType = e.target.value
    }
  }
}
</script>
