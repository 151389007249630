import Reveal from "stimulus-reveal-controller"

export default class extends Reveal {
  static targets = ['field']

  toggle () {
    this.fieldTargets.forEach(field => {
      field.disabled = !field.disabled
    })

    super.toggle()
  }
}
