<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{active: active}" slot-scope="{ setActive, active }"  v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.right-0.z-40.pb-10.px-12(class="xl:min-w-dropdown-more xl:py-8 xl:shadow xl:w-auto xl:absolute" v-show="active")
          reference(
            :initial-reference="searchParams.by_reference"
            @change="updateLabel"
          )
          facilities(
            :initial-facilities="searchParams.by_facilities"
            :accommodation-facilities="accommodationFacilities"
            @change="updateLabel"
          )
          heating-types(
            :searchParams="searchParams"
            :accommodation-heating-types="accommodationHeatingTypes"
            :accommodation-heating-modes="accommodationHeatingModes"
            @change="updateLabel"
          )

</template>

<script>
import Reference from '@components/Searchbar/MoreCriterias/Reference'
import Facilities from '@components/Searchbar/MoreCriterias/Facilities'
import HeatingTypes from '@components/Searchbar/MoreCriterias/HeatingTypes'

export default {
  components: {
    Reference,
    Facilities,
    HeatingTypes
  },

  props: {
    searchParams: {
      type: Object,
      required: true
    },

    accommodationFacilities: {
      type: Array,
      required: true
    },

    accommodationHeatingTypes: {
      type: Array,
      required: true
    },

    accommodationHeatingModes: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      label: '+ de critères'
    }
  },

  methods: {
    updateLabel (label) {
      this.label = label
    }
  }
}
</script>
