import { gsap, TweenLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class {
  constructor () {
    this.$readmore = document.querySelectorAll('.readmore')

    if (this.$readmore) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$readmore.forEach(element => {
      new Readmore(element)
    })
  }
}

class Readmore {
  constructor (item) {
    this.$readmore = item
    this.$readmoreData = this.$readmore.dataset.readmore
    this.$readmoreContent = this.$readmore.querySelector('.readmore__content')
    this.$readmoreLink = this.$readmore.querySelector('.readmore__link')

    this.isOpen = false

    this.bindEvents()
  }

  bindEvents () {
    this.$readmoreLink.innerHTML = "Lire la suite"
    this.heightCurrentTextIsToHigh()
    this.openAnimation()
    this.$readmoreLink.addEventListener('click', this.openAnimation.bind(this))
  }

  heightCurrentTextIsToHigh () {
    if (this.$readmoreContent.offsetHeight > this.$readmoreData) {
      TweenLite.to(this.$readmoreContent, 0.2, { maxHeight: this.$readmoreData, overflow: 'hidden' })
      TweenLite.set(this.$readmoreLink, { opacity: '1' })
    }
  }

  openAnimation () {
    if (!this.isOpen) {
      TweenLite.to(this.$readmoreContent, 0.5, { height: this.$readmoreData })
      this.$readmoreLink.innerHTML = "Lire la suite"
    } else {
      TweenLite.set(this.$readmoreContent, { height: 'auto' })
      TweenLite.from(this.$readmoreContent, 0.5, { height: this.$readmoreData })
      this.$readmoreLink.innerHTML = "Réduire"
    }

    this.isOpen = !this.isOpen
  }
}
