<template lang="pug">
  div
    slot(v-for="(item, itemIndex) in mutableItems" :item="item" :item-index="itemIndex" :remove-item="removeItem")

    button(
      type="button"
      class="btn btn-lg btn-primary mb-8"
      @click.prevent="addItem"
    )
      | {{ addItemBtnLabel }}

</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => []
      },
      addItemBtnLabel: {
        type: String,
        default: "Ajouter"
      }
    },

    data () {
      return {
        mutableItems: this.items
      }
    },

    methods: {
      addItem () {
        this.mutableItems.push({})
      },

      removeItem (itemIndex) {
        this.mutableItems.splice(itemIndex, 1)
      }
    }
  }
</script>
