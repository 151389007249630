import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pole']

  onChange (e) {
    if (parseInt(e.target.dataset.agencyRef) === 63510) {
      this.poleTarget.classList.toggle('d-none', !e.target.checked)
    }
  }
}
