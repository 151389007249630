import { gsap, TimelineLite, CSSPlugin, TextPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(TextPlugin)

export default class {
  constructor () {
    this.$valueItems = document.querySelectorAll('.values__item')

    if (this.$valueItems) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$valueItems.forEach(element => {
      new ValueItem(element)
    })
  }
}

class ValueItem {
  constructor (item) {
    this.$valueItem = item
    this.$valuePhrase = document.querySelector('.values__quote')
    this.$valueAuthor = document.querySelector('.values__author')
    this.$valueItems = document.querySelectorAll('.values__item')
    this.$valuePhraseData = this.$valueItem.dataset.phrase
    this.$valueAuthorData = this.$valueItem.dataset.author

    this.bindEvents()
  }

  bindEvents () {
    this.$valueItem.addEventListener('click', this.openAnimation.bind(this))
  }

  openAnimation () {
    this.removeAllActiveItem()
    this.$valueItem.classList.add("active")

    const tl = new TimelineLite()
    tl.to(this.$valuePhrase, 0.5, { opacity: 0 }, "appear")
    tl.to(this.$valueAuthor, 0.5, { opacity: 0 }, "appear")
    tl.set(this.$valuePhrase, { text: this.$valuePhraseData })
    tl.set(this.$valueAuthor, { text: this.$valueAuthorData })
    tl.to(this.$valuePhrase, 0.5, { opacity: 1 }, "disappear")
    tl.to(this.$valueAuthor, 0.5, { opacity: 1 }, "disappear")
  }

  removeAllActiveItem () {
    this.$valueItems.forEach(element => {
      element.classList.remove("active")
    })
  }
}
