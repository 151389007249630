<template lang="pug">
  cleave(
    v-model="input"
    :options="options"
    class="form-control"
    v-bind="$attrs"
  )
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave
  },

  props: {
    value: {
      type: [Number, String],
      default: null
    },

    cleaveOptions: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    options () {
      const options = {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        delimiter: ' ',
        numeralDecimalMark: '.'
      }

      return {
        ...options,
        ...this.cleaveOptions
      }
    },

    input: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
