<template lang="pug">
  div
    contract-types(
      :contract-types="contractTypes"
      :accommodation-ages="accommodationAges"
      :companies-root-path="companiesRootPath"
      :programs-root-path="programsRootPath"
      :search-params="searchParams"
    )
    locations-select(
      :initial-cities="initialCities"
      :search-params="searchParams"
    )
    room-checkboxes(:initial-rooms="searchParams.by_rooms")
    accommodation-types-checkboxes(
      :initial-accommodation-types="searchParams.by_accommodation_types"
      :accommodation-types="accommodationTypes"
    )
    budget-range(:search-params="searchParams")
    surface-range(:search-params="searchParams")
    more-criterias(
      :search-params="searchParams"
      :accommodation-facilities="accommodationFacilities"
      :accommodation-heating-types="accommodationHeatingTypes"
      :accommodation-heating-modes="accommodationHeatingModes"
    )

    div(class='dropdown relative inline-block')
      a(:href="resetPath" class='w-full border-2 border-gray-400 relative text-base text-primary font-normal pl-8 pr-8 text-left inline-block rounded leading-10 overflow-hidden hidden xl:block fa-flip-horizontal py-icon')
        i.fal.fa-undo.text-lg
</template>

<script>
import ContractTypes from '@components/Searchbar/ContractTypes'
import LocationsSelect from '@components/Searchbar/LocationsSelect'
import RoomCheckboxes from '@components/Searchbar/RoomCheckboxes'
import AccommodationTypesCheckboxes from '@components/Searchbar/AccommodationTypesCheckboxes'
import BudgetRange from '@components/Searchbar/BudgetRange'
import SurfaceRange from '@components/Searchbar/SurfaceRange'
import MoreCriterias from '@components/Searchbar/MoreCriterias'

export default {
  components: {
    ContractTypes,
    LocationsSelect,
    RoomCheckboxes,
    AccommodationTypesCheckboxes,
    BudgetRange,
    SurfaceRange,
    MoreCriterias
  },

  props: {
    searchParams: {
      type: Object,
      required: true
    },

    accommodationTypes: {
      type: Array,
      required: true
    },

    accommodationFacilities: {
      type: Array,
      required: true
    },

    accommodationHeatingTypes: {
      type: Array,
      required: true
    },

    accommodationHeatingModes: {
      type: Array,
      required: true
    },

    contractTypes: {
      type: Array,
      required: true
    },

    accommodationAges: {
      type: Array,
      required: true
    },

    companiesRootPath: {
      type: String,
      required: true
    },

    programsRootPath: {
      type: String,
      required: true
    },

    resetPath: {
      type: String,
      required: true
    },

    initialCities: {
      type: Array,
      required: true
    }
  },

  created () {
    Object.entries(this.searchParams).forEach(([key, value]) => {
      this.$store.commit('updateParams', { key: key, value: value })
    })
  }
}
</script>
