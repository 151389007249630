export default class {
  constructor () {
    this.$advertisement = document.querySelectorAll('.advertisement')

    if (this.$advertisement) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$advertisement.forEach(element => {
      new Advertisement(element)
    })
  }
}

class Advertisement {
  constructor (item) {
    this.$advertisement = item
    this.$advertisementClose = this.$advertisement.querySelector('.advertisement__close')
    this.$advertisementClickOutside = this.$advertisement.querySelector('.advertisement__click-outside')

    this.bindEvents()
  }

  bindEvents () {
    this.$advertisementClose.addEventListener('click', () => { this.disapearAnimation() })
    this.$advertisementClickOutside.addEventListener('click', () => { this.disapearAnimation() })
  }

  disapearAnimation () {
    this.$advertisement.classList.add("disabled")
  }
}
