export default class {
  constructor () {
    this.$menu = document.querySelectorAll('.header')

    if (this.$menu) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$menu.forEach(element => {
      new Header(element)
    })
  }
}

class Header {
  constructor (item) {
    this.$menu = item
    this.$burger = this.$menu.querySelector('.header__burger')
    this.body = document.body
    this.html = document.documentElement
    this.$mobileWrapper = this.$menu.querySelector('.header__mobile-wrapper')

    this.isOpen = false

    this.bindEvents()
  }

  bindEvents () {
    this.$burger.addEventListener('click', this.openAnimation.bind(this))
  }

  openAnimation () {
    this.$menu.classList.toggle("active", !this.isOpen)
    this.$burger.classList.toggle("active", !this.isOpen)
    this.body.classList.toggle("overflow-hidden-body", !this.isOpen)
    this.html.classList.toggle("overflow-hidden-html", !this.isOpen)
    this.isOpen = !this.isOpen
  }
}
