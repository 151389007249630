import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']

  success () {
    this.formTarget.reset()
    new FlashMessage('Vous êtes désormais abonné à notre newsletter !', 'success')
  }

  error () {
    new FlashMessage('Une erreur est survenue. Merci de réessayer plus tard.', 'danger')
  }
}
