function formatCity (city) {
  return {
    text: city.attributes.nameWithZipcode,
    originalText: city.attributes.nameWithZipcode,
    type: city.type,
    id: city.id
  }
}

function formatSector (sector) {
  return {
    text: sector.attributes.title,
    originalText: sector.attributes.title,
    type: sector.type,
    id: sector.id
  }
}

function formatCities (cities) {
  return cities.map(city => formatCity(city))
}

function formatSectors (sectors) {
  return sectors.map(sector => formatSector(sector))
}

function formatLocations ({ cities }) {
  return [
    {
      label: 'Villes',
      values: formatCities(cities)
    }
  ]
}

export {
  formatCity,
  formatSector,
  formatCities,
  formatSectors,
  formatLocations
}
