import { gsap, TimelineLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default () => {
  const element = document.querySelectorAll('[data-value]')
  if (element === null) return

  const intersectionObserverScroll = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const tl = new TimelineLite()
        // slide animation
        tl.to(entry.target.querySelector('.values__top-right'), 1, { top: "0%", right: "5%", ease: 'power2' }, "slide")
        tl.to(entry.target.querySelector('.values__bottom-right'), 1, { bottom: "0%", right: "0%", ease: 'power2' }, "slide+=0.7")
        tl.to(entry.target.querySelector('.values__bottom-left'), 1, { bottom: "0%", left: "0%", ease: 'power2' }, "slide+=1.4")
        tl.to(entry.target.querySelector('.values__top-left'), 1, { top: "0%", left: "5%", ease: 'power2' }, "slide+=2.1")
      }
    })
  }, {
    rootMargin: '0px',
    threshold: 0.5
  })

  element.forEach(animateElement => intersectionObserverScroll.observe(animateElement))
}
