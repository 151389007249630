export default class {
  constructor () {
    this.$downloadCard = document.querySelectorAll('.download-card')

    if (this.$downloadCard) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$downloadCard.forEach(element => {
      new DownloadCard(element)
    })
  }
}

class DownloadCard {
  constructor (item) {
    this.$downloadCard = item
    this.$downloadCardFileName = this.$downloadCard.querySelector('.download-card__file-name')
    this.$downloadCardFileInput = this.$downloadCard.querySelector('.download-card__input')
    this.$downloadCardFileButton = this.$downloadCard.querySelector('.download-card__button')
    this.$downloadCardFileButtonText = this.$downloadCardFileButton.querySelector('p')

    this.bindEvents()
  }

  bindEvents () {
    this.$downloadCardFileInput.addEventListener('change', (e) => { this.updateDownloadButtonHandler(e) })
  }

  updateDownloadButtonHandler (e) {
    const fileName = e.target.value.split('\\').pop()
    const fileNameDisplayed = fileName.length <= 25 ? fileName : (fileName.substring(0, 25) + '...')
    this.$downloadCardFileName.innerHTML = fileNameDisplayed
    this.$downloadCardFileButtonText.innerHTML = "Mettre à jour"
    this.$downloadCardFileButton.classList.add('active')
  }
}
