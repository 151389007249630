import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link', 'input']

  onChange () {
    const linkHref = `https://www.homeloop.fr/estimation-benedic?address=${this.inputTarget.value}&${this.linkTarget.dataset.utmParams}`

    this.linkTarget.setAttribute("href", linkHref)
  }
}
